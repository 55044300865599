import { en } from '@/app/user/constants';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { useSetSectionsDataAndPreview } from '@/app/editor/sections/hooks/useSetSectionsDataAndPreview';
import Workspace from '@/app/workspaces/components/_Main';
import { useActiveWorkspaceId } from '@/app/workspaces/hooks/useActiveWorkspaceId';
import { useWorkspaceInitialize } from '@/app/workspaces/hooks/useWorkspaceInitialize';
import DefaultLayout from '@/ui/layouts/Default/DefaultLayout';
import { fetchSectionData } from '@/utils/fetchSectionData';
import nextI18nextConfig from 'next-i18next.config';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { SectionResource } from '@/app/editor/sections/types';
import type { GetStaticProps } from 'next';
import type { NextPageWithLayout } from 'types/generic';

interface Props {
    sectionsData: SectionResource[];
    sectionPreviews: { [blockId: string]: BlockResource };
}

const Home: NextPageWithLayout<Props> = ({ sectionsData, sectionPreviews }) => {
    useSetSectionsDataAndPreview({ sectionsData, sectionPreviews });

    const workspaceId = useActiveWorkspaceId();
    const isReady = useWorkspaceInitialize(workspaceId);

    if (!isReady) {
        return null;
    }

    return <Workspace id={workspaceId} />;
};

export const getStaticProps = (async ({ locale }) => {
    const pageLocale = locale ?? en;
    const sectionData = await fetchSectionData(pageLocale);

    return {
        props: {
            ...(await serverSideTranslations(
                pageLocale,
                [
                    'campaigns',
                    'navigation',
                    'common',
                    'support',
                    'billing',
                    'funnelSettings',
                    'mediaLibrary',
                    'workspaces',
                    'messages',
                    'crm',
                ],
                nextI18nextConfig,
            )),
            ...sectionData,
        },
    };
}) satisfies GetStaticProps;

Home.getLayout = (page) => {
    return (
        <DefaultLayout showWorkspaceSelector showTopLevelNavItems showInviteButton>
            {page}
        </DefaultLayout>
    );
};

export default Home;
